import React from 'react';
import CSSParallax from '../../components/CSSParallax';
import { LAYERS } from '../../constants/parallax';
import theme from '../../styles/theme';
import { StyledSection } from './styles';

const getDividerPropertiesForColor = (color) => {
  switch (color) {
    case theme.colors.hotPink:
      return {
        variant: 2,
        flipX: false,
      };
    case theme.colors.babyPink:
      return {
        variant: 7,
        flipX: true,
      };
    case theme.colors.green:
      return {
        variant: 1,
        flipX: false,
      };
    case theme.colors.blue:
      return {
        variant: 4,
        flipX: true,
      };
    default:
      return {
        variant: 5,
        flipX: true,
      };
  }
};

const TallBlack: React.FunctionComponent<any> = ({
  children,
  isFirstSection,
  pageHasAnnouncement,
  topDividerColor,
  noFooterBlocks,
  natural,
}) => (
  <StyledSection natural={natural}>
    <CSSParallax.Content isFirstSection={isFirstSection} pageHasAnnouncement={pageHasAnnouncement} natural={natural}>
      <CSSParallax.Dividers.BrushStroke {...getDividerPropertiesForColor(topDividerColor)} flipY={true} color={topDividerColor} />
      <CSSParallax.Padder height={7} />
      <CSSParallax.ContentInner>{children}</CSSParallax.ContentInner>
      <CSSParallax.Padder height={10} />
      {!noFooterBlocks && <CSSParallax.Dividers.BrushStroke variant={4} color={theme.colors.purple} />}
    </CSSParallax.Content>
    <CSSParallax.Layer depth={2} natural={natural}>
      <CSSParallax.Greeblies.PinkSplatter1 top={40} flipX={true} left={0} />
      <CSSParallax.Greeblies.BlueSplatter2 top={20} right={0} />
    </CSSParallax.Layer>
    <CSSParallax.Layer appearance={LAYERS.DEEP_BACKGROUND} depth={0} />
  </StyledSection>
);

export default TallBlack;
