import React from 'react';
import CSSParallax from '../../components/CSSParallax';
import { StyledSection } from './styles';
import { StyledContent, StyledPadder, StyledOverspray } from '../ColorHeaderStyles';

const ColorHeaderPink: React.FunctionComponent<any> = ({ children, isFirstSection, pageHasAnnouncement, natural }) => (
  <StyledSection natural={natural}>
    <StyledOverspray />
    <StyledContent isFirstSection={isFirstSection} pageHasAnnouncement={pageHasAnnouncement} natural={natural}>
      <StyledPadder />
      <CSSParallax.ContentInner>{children}</CSSParallax.ContentInner>
      <StyledPadder />
    </StyledContent>
    <CSSParallax.Layer depth={2} natural={natural}>
      <CSSParallax.Greeblies.GreenSplatter1 top={0} />
      <CSSParallax.Greeblies.BlueSplatter1 top={0} right={0} />
      <CSSParallax.Greeblies.WhiteSplatter3 top={0} />
      <CSSParallax.Greeblies.GreenSplatter2 top={0} />
    </CSSParallax.Layer>
    <CSSParallax.Layer depth={1} natural={natural}>
      <CSSParallax.Greeblies.StageHeader3 />
    </CSSParallax.Layer>
  </StyledSection>
);

export default ColorHeaderPink;
