import styled from 'styled-components';
import CSSParallax from '../../components/CSSParallax';
import { ThemeType } from '../../types';

export const StyledSection = styled(CSSParallax.Section)(
  ({ theme: { colors, mobileView } }: ThemeType) => `
    background-color: ${colors.hotPink};
    color: ${colors.white};
    position: relative;
    z-index: 2;
    margin-bottom: -1px;
    margin-top: -1px;
    min-height: 500px;

    ${mobileView} {
      min-height: 0;
    }
`,
);
