import styled from 'styled-components';
import CSSParallax from '../components/CSSParallax';
import Overspray from '../components/Overspray/styles';
import { ThemeType } from '../types';
import { HeroWrapper } from '../blocks/Hero/styles';

export const StyledOverspray = styled(Overspray)(
  ({ theme: { mobileView } }: ThemeType) => `
      height: 35vw;
      opacity: 1;
  
      ${mobileView} {
        height: 80vh;
        max-height: 100%;
      }
  `,
);

export const StyledContent = styled(CSSParallax.Content)(
  ({ theme: { spacing } }: ThemeType) => `
      min-height: 0;
  
      ${HeroWrapper} {
        padding-top: ${spacing.x4};
        padding-bottom: ${spacing.x2};
      }
  `,
);

export const StyledPadder = styled(CSSParallax.Padder)(
  ({ theme: { mobileView } }: ThemeType) => `
      padding-top: 2vw;
  
      ${mobileView} {
        padding-top: 0;
        display: none;
      }
  `,
);
